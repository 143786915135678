.empty {
  height: 100%;

  &__content {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
