.switch {
  --toggle-size: 20px;

  position: relative;
  width: calc(var(--toggle-size) * 2);
  height: var(--toggle-size);
  border-radius: 25% / 50%;
  background-color: var(--color-gray-extra-light);
  transition: 0.2s;
  border: none;

  &[data-disabled="true"] {
    opacity: 0.7;

    input {
      cursor: not-allowed;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    cursor: pointer;
    width: inherit;
    height: inherit;
    opacity: 0;
  }

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: var(--toggle-size);
    height: var(--toggle-size);
    border-radius: 50%;
    background-color: var(--color-white);
    border: 4px solid var(--color-gray-extra-light);
    transition: 0.15s;
    top: 0;
    left: 0;
  }

  &_active {
    background-color: var(--color-orange);

    &::before {
      transform: translateX(100%);
      border-color: var(--color-orange);
      background-color: var(--color-white);
    }
  }
}
