:root {
  --color-bg-hover: #f6f8fc;
  --color-bg-hover-black: #2e3545;
  --color-black: #000;
  --color-b-60: rgba(24, 29, 39, 60%);
  --color-blue: #48acf5;
  --color-dark: #181d27;
  --color-white: #fff;
  --color-white-60: rgba(255, 255, 255, 60%);
  --color-white-light: #eff1f5;
  --color-white-middle: #e8eaed;
  --color-white-dark: #c5c7c9;
  --color-gray-dark: #3d3d3f;
  --color-gray-medium: #666;
  --color-gray: #7c7e85;
  --color-gray-extra-light: #dadce0;
  --color-gray-light: #ccced5;
  --color-gray49: #7d7d7d;
  --color-gray-disabled: #cdd4db;
  --color-orange: #f80;
  --color-orange-light: #fff2e5;
  --color-yellow: #ffb931;
  --color-yellow-light: #ffc961;
  --color-yellow-circle: #ffb931;
  --color-red: #f54848;
  --color-red-hover: #f96b6b;
  --color-violet: #9747ff;
  --color-green: #6bda61;
  --color-green-integration: #6bd96199;
  --color-empty: #c0c6d7;
  --color-stroke-light: #cfcffd;
  --color-placeholder: #dcdfe8;
  --color-gray-icon: #6c7c8c;
  --color-bg-disabled: #f3f4f4;
  --font-primary: "Muller", sans-serif;
  --gx-spacing-x-small: 0;
  --gx-toggle-size: 80px;
  --color-connect: var(--color-dark);
  --height-input: 116px;
  --dark-blur: rgba(30, 30, 30, 40%);

  // * Animations time
  --transition-small: 0.3s;
  --transition-fast: 0.15s;
  --transition-medium: 0.5s;
  --transition-large: 0.8s;
  --z-index-small: 1;
  --z-index-medium: 10;
  --z-index-large: 100;

  // CURRENT PROJECT
  --color-lightgray: #dcdfe8;
  --color-gray-line: #d3d7e3;
  --color-originorange: #f80;
  --color-red-light: #f548481a;
  --color-blue2: #3787ff;
  --color-lightblue: #67bfff;
  --color-row-blue: #f5f8fd;
  --color-lightorange: #fff2e5;
  --color-box-shadow: rgba(125, 125, 125, 30%);
  --color-box-shadow-dark: rgba(0, 0, 0, 30%);
  --color-box-shadow-light: rgba(0, 0, 0, 15%);
  --color-box-shadow-extra-light: rgba(0, 0, 0, 5%);
  --color-orange-active: rgba(255, 136, 0, 10%);
  --color-dark-gradient: rgba(255, 255, 255, 0%);
  --nav-bar-height: 58px;
}
