@mixin media-h-1680 {
  @media screen and (height <= 1680px) {
    @content;
  }
}

@mixin media-h-1536 {
  @media screen and (height <= 1536px) {
    @content;
  }
}

@mixin media-w-1024-h-1280 {
  @media screen and (height <= 1280px) and (width <= 1024px) {
    @content;
  }
}

@mixin media-w-900-h-1440 {
  @media screen and (height <= 1440px) and (width <= 900px) {
    @content;
  }
}

@mixin media-h-1366 {
  @media screen and (height <= 1366px) {
    @content;
  }
}

@mixin media-h-1024 {
  @media screen and (height <= 1024px) {
    @content;
  }
}

@mixin media-h-780 {
  @media screen and (height <= 780px) {
    @content;
  }
}

@mixin media-w-1024 {
  @media screen and (width <= 1024px) {
    @content;
  }
}

@mixin media-w-780 {
  @media screen and (width <= 780px) {
    @content;
  }
}

@mixin media-w-720-768 {
  @media screen and (width >= 720px) and (width <= 768px) {
    @content;
  }
}

@mixin media-w-700 {
  @media screen and (width <= 700px) {
    @content;
  }
}

@mixin media-w-640 {
  @media screen and (width <= 640px) {
    @content;
  }
}

@mixin media-w-810 {
  @media screen and (width <= 810px) {
    @content;
  }
}

@mixin media-w-900 {
  @media screen and (width <= 900px) {
    @content;
  }
}

@mixin media-w-2560 {
  @media screen and (width <= 2560px) {
    @content;
  }
}

@mixin media-w-1920 {
  @media screen and (width <= 1920px) {
    @content;
  }
}

@mixin media-w-1700 {
  @media screen and (width <= 1700px) {
    @content;
  }
}

@mixin media-w-1600 {
  @media screen and (width <= 1600px) {
    @content;
  }
}

@mixin media-w-1460 {
  @media screen and (width <= 1460px) {
    @content;
  }
}

@mixin media-w-1366 {
  @media screen and (width <= 1366px) {
    @content;
  }
}

@mixin media-w-1200 {
  @media screen and (width <= 1200px) {
    @content;
  }
}

@mixin media-w-768 {
  @media screen and (width <= 768px) {
    @content;
  }
}

@mixin media-w-568 {
  @media screen and (width <= 568px) {
    @content;
  }
}

@mixin media-w-420 {
  @media screen and (width <= 420px) {
    @content;
  }
}

@mixin media-w-375 {
  @media screen and (width <= 375px) {
    @content;
  }
}

@mixin media-w-360 {
  @media screen and (width <= 360px) {
    @content;
  }
}

@mixin media-w-320 {
  @media screen and (width <= 320px) {
    @content;
  }
}

@mixin font-size($size: 100, $wight: 700, $color: var(--color-dark)) {
  font-family: var(--font-primary);
  font-style: normal;
  font-size: 80px;
  line-height: 80px;
  color: $color;
  font-weight: $wight;

  @if $size == 46 {
    font-size: 46px;
    line-height: 46px;

    @include media-w-1024 {
      font-size: 36px;
      line-height: 36px;
    }

    @include media-w-810 {
      font-size: 28px;
      line-height: 28px;
    }

    @include media-w-780 {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @if $size == 40 {
    font-size: 40px;
    line-height: 40px;

    @include media-w-1024 {
      font-size: 30px;
      line-height: 30px;
    }

    @include media-w-810 {
      font-size: 25px;
      line-height: 25px;
    }

    @include media-w-780 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @if $size == 36 {
    font-size: 36px;
    line-height: 36px;

    @include media-w-1024 {
      font-size: 32px;
      line-height: 32px;
    }

    @include media-w-810 {
      font-size: 28px;
      line-height: 28px;
    }

    @include media-w-780 {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @if $size == 32 {
    font-size: 32px;
    line-height: 120%;
  }

  @if $size == 30 {
    font-size: 30px;
    line-height: 30px;

    @include media-w-1024 {
      font-size: 25px;
      line-height: 25px;
    }

    @include media-w-810 {
      font-size: 20px;
      line-height: 20px;
    }

    @include media-w-780 {
      font-size: 15px;
      line-height: 15px;
    }
  }

  @if $size == 24 {
    font-size: 24px;
    line-height: 24px;

    @include media-w-1024 {
      font-size: 20px;
      line-height: 20px;
    }

    @include media-w-810 {
      font-size: 16px;
      line-height: 16px;
    }

    @include media-w-780 {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @if $size == 18 {
    font-size: 18px;
    line-height: 18px;

    @include media-w-1024 {
      font-size: 18px;
      line-height: 18px;
    }

    @include media-w-810 {
      font-size: 18px;
      line-height: 18px;
    }

    @include media-w-780 {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @if $size == 14 {
    font-size: 14px;
    line-height: 14px;

    @include media-w-1024 {
      font-size: 14px;
      line-height: 14px;
    }

    @include media-w-810 {
      font-size: 14px;
      line-height: 14px;
    }

    @include media-w-780 {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @if $size == 13 {
    font-size: 13px;
    line-height: 13px;

    @include media-w-1024 {
      font-size: 13px;
      line-height: 13px;
    }

    @include media-w-810 {
      font-size: 13px;
      line-height: 13px;
    }

    @include media-w-780 {
      font-size: 13px;
      line-height: 13px;
    }
  }

  @if $size == 12 {
    font-size: 12px;
    line-height: 12px;

    @include media-w-1024 {
      font-size: 12px;
      line-height: 12px;
    }

    @include media-w-810 {
      font-size: 12px;
      line-height: 12px;
    }

    @include media-w-780 {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

@mixin select-input($height: 116px) {
  height: $height;

  @include media-w-900 {
    height: calc($height - 26px);
  }

  @include media-w-810 {
    height: calc($height - 36px);
  }

  @include media-w-700 {
    height: calc($height - 56px);
  }
}

@mixin select-input-padding {
  padding-right: 30px;
  padding-left: 30px;

  @include media-w-900 {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include media-w-810 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@mixin wrapper-padding {
  padding-right: 40px;
  padding-left: 40px;

  @include media-w-1024 {
    padding-right: 30px;
    padding-left: 30px;
  }

  @include media-w-700 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@mixin form-gap($gap: 40px) {
  gap: $gap;

  @include media-w-900 {
    gap: calc($gap - 5px);
  }

  @include media-w-780 {
    gap: calc($gap - 10px);
  }

  @include media-w-700 {
    gap: calc($gap - 20px);
  }
}

@mixin main-border-radius {
  border-radius: 20px !important;
}

@mixin media-label-input {
  @include media-w-720-768 {
    font-size: 23px;
    line-height: 23px;
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    background: var(--color-bg-hover);
  }

  @include media-w-900 {
    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-orange);
  }
}

@mixin input {
  input {
    height: 40px;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-lightgray);
    border-radius: 8px;
    font-size: 16px;
    color: var(--color-gray-icon);

    &:hover {
      border-color: var(--color-dark);
    }

    &:focus {
      outline: none !important;
      border-color: var(--color-orange);
      color: var(--color-dark);
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 20px;
    height: 20px;
    background: url("/src/assets/icon/calendar.svg") no-repeat;
  }

  input[type="date"]:focus::-webkit-calendar-picker-indicator {
    background: url("/src/assets/icon/calendarOrange.svg") no-repeat;
  }
}

@mixin table-hover-border($color: var(--color-orange)) {
  td {
    transition: 0.3s;
    box-shadow:
      0 -1px 0 0 $color,
      0 1px 0 0 $color;
  }

  td:first-child {
    box-shadow:
      -1px 0 0 0 $color,
      0 -1px 0 0 $color,
      0 1px 0 0 $color;
  }

  td:last-child {
    box-shadow:
      1px 0 0 0 $color,
      0 -1px 0 0 $color,
      0 1px 0 0 $color;
  }
}

@mixin ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin row-flex() {
  display: flex;
  flex-wrap: wrap;
}

@mixin hard-size($width, $height) {
  width: #{$width}px;
  height: #{$height}px;
}

/**
* t - top
* w - wrap
* n - nowrap
* s - space-between
*/
@mixin flex-col($type: "t", $wrap: "w") {
  display: flex;
  flex-direction: column;

  @if $type == "t" {
    justify-content: flex-start;
  }

  @if $type == "s" {
    justify-content: space-between;
  }

  @if $wrap == "w" {
    flex-wrap: wrap;
  }

  @if $type == "n" {
    flex-wrap: nowrap;
  }
}

/**
* l - left
* r - right
* t - top
* b - bottom
* c - center
* s - space-between
*/
@mixin flex($type) {
  display: flex;

  @if $type == "lc" {
    justify-content: flex-start;
    align-items: center;
  }

  @if $type == "sc" {
    justify-content: space-between;
    align-items: center;
  }

  @if $type == "rc" {
    justify-content: flex-end;
    align-items: center;
  }

  @if $type == "cc" {
    justify-content: center;
    align-items: center;
  }

  @if $type == "ct" {
    justify-content: center;
    align-items: flex-start;
  }

  @if $type == "lt" {
    justify-content: flex-start;
    align-items: flex-start;
  }

  @if $type == "st" {
    justify-content: space-between;
    align-items: flex-start;
  }

  @if $type == "rt" {
    justify-content: flex-end;
    align-items: flex-start;
  }

  @if $type == "cb" {
    justify-content: center;
    align-items: flex-end;
  }

  @if $type == "lb" {
    justify-content: flex-start;
    align-items: flex-end;
  }

  @if $type == "sb" {
    justify-content: space-between;
    align-items: flex-end;
  }

  @if $type == "rb" {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@mixin col($colsize, $mrg) {
  width: calc(100% / 12 * #{$colsize} - #{$mrg * 2}px - 0.1px);
}

@mixin ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin full-space {
  width: 100%;
  height: 100%;
}

@mixin container-storage-details {
  padding: 20px 30px 40px;
  height: 100%;

  @include media-w-1460 {
    padding: 20px;
  }

  @include media-w-640 {
    padding: 20px 10px;
  }
}

@mixin table-wrap {
  min-height: 720px;

  @include media-h-1024 {
    min-height: 0;
    flex: 1 0 auto;
    max-height: 675px;
  }
}

@mixin tab-group-full-height {
  .tab-group {
    height: 100%;

    &::part(base),
    &::part(body) {
      height: 100%;
    }

    &__panel {
      height: 100%;

      &::part(base) {
        height: 100%;
      }
    }
  }
}

@mixin data-scrollend {
  &[data-scrollend="true"] {
    &::after {
      content: "";
      height: 60px;
      width: 99%;
      position: absolute;
      background: linear-gradient(180deg, var(--color-dark-gradient) 0%, var(--color-white) 100%);
      bottom: 0;
    }
  }
}

@mixin bg-line-img {
  background-image: url("../../assets/image/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
